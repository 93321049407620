<template>
  <div id="userOrder">
    <Dialog ref="dialog" :config="config" />
    <nav>
      <span
        @click="changestatus('全部')"
        :class="form.status == '全部' ? 'active' : ''"
        ><span class="label">{{ $fanyi("全部") }}</span
        ><span class="numberCount"
          >({{ getStatisticsData("全部状态") }})</span
        ></span
      >
      <span
        @click="changestatus('等待报价')"
        :class="form.status == '等待报价' ? 'active' : ''"
        ><span class="label">{{ $fanyi("等待报价") }}</span
        ><span class="numberCount"
          >({{ getStatisticsData("等待报价") }})</span
        ></span
      >
      <span
        @click="changestatus('等待支付')"
        :class="form.status == '等待支付' ? 'active' : ''"
        ><span class="label">{{ $fanyi("待处理的订单") }}</span
        ><span class="numberCount"
          >({{ getStatisticsData("等待支付") }})</span
        ></span
      >
      <span
        @click="changestatus('待发货')"
        :class="form.status == '待发货' ? 'active' : ''"
        ><span class="label">{{ $fanyi("待发货") }}</span
        ><span class="numberCount"
          >({{ getStatisticsData("待发货配送单") }})</span
        ></span
      >
      <span
        @click="changestatus('国际运输中')"
        :class="form.status == '国际运输中' ? 'active' : ''"
        ><span class="label">{{ $fanyi("运输中") }}</span
        ><span class="numberCount"
          >({{ getStatisticsData("运输中配送单") }})</span
        ></span
      >
      <span
        @click="changestatus('欧盟境内派送')"
        :class="form.status == '欧盟境内派送' ? 'active' : ''"
        ><span class="label">{{ $fanyi("欧盟内部交付") }}</span
        ><span class="numberCount"
          >({{ getStatisticsData("西班牙派送配送单") }})</span
        ></span
      >
      <span
        @click="changestatus('已签收')"
        :class="form.status == '已签收' ? 'active' : ''"
        ><span class="label">{{ $fanyi("已签收2") }}</span
        ><span class="numberCount"
          >({{ getStatisticsData("已签收配送单") }})</span
        ></span
      >
    </nav>
    <div>
      <div class="searchBox">
        <div class="myInput" style="width: 360px;">
          <i class="el-icon-search"></i>
          <input
            type="text"
            v-model="form.searchText"
            @keydown.enter="getData(form)"
            :placeholder="$fanyi('请输入关键词搜索')"
          />
        </div>
        <div class="myInput" style="width: 280px;">
          <i class="el-icon-search"></i>
          <input
            type="text"
            v-model="form.express_no"
            @keydown.enter="getData(form)"
            :placeholder="$fanyi('输入跟踪号码。')"
          />
        </div>
        <div class="myInput" style="width:345px;">
          <i class="el-icon-search"></i>
          <input
            type="text"
            v-model="form.searchGoods"
            @keydown.enter="getData(form)"
            :placeholder="$fanyi('输入ID 1688/ product link')"
          />
        </div>
        <el-date-picker
          v-model="value1"
          type="datetimerange"
          :range-separator="$fanyi('至')"
          :start-placeholder="$fanyi('开始日期')"
          :end-placeholder="$fanyi('结束日期')"
          format="dd-MM-yyyy HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          @change="setFromDate"
        >
        </el-date-picker>
        <button
          class="searchBtn"
          @click="
            form.page = 1;
            getData();
          "
        >
          {{ $fanyi("搜索") }}
        </button>
        <button
          class="fontBlueBtn_h"
          @click="
            form = { page: 1, pageSize: 10, currentPage: 1 };
            value1 = [];
            getData();
          "
        >
          {{ $fanyi("明白") }}
        </button>
      </div>
      <!-- 表格 -->
      <div class="dataBody">
        <el-table
          :data="tableData"
          border
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
          :key="oKey"
        >
          <el-table-column label="Nº" width="50">
            <template slot-scope="scope">
              <div>
                {{ (form.page - 1) * 10 + scope.$index + 1 }}
              </div>
            </template>
          </el-table-column>
          <!-- 订单号 -->
          <el-table-column
            property="name"
            :label="$fanyi('订单号')"
            width="150"
          >
            <template slot-scope="scope">
              <div>
                <span
                  class="aLink"
                  @click="
                    $fun.routerToPage(
                      '/OrderDetails?type=' +
                        scope.row.statusName +
                        '&order_sn=' +
                        (scope.row.porder_detail[0].order_detail || {})
                          .order_sn,
                      true
                    )
                  "
                >
                  {{ (scope.row.porder_detail[0].order_detail || {}).order_sn }}
                </span>
              </div>
            </template>
          </el-table-column>
          <!-- 配送单号 -->
          <el-table-column
            property="date"
            :label="$fanyi('交货订单')"
            width="150"
          >
            <template slot-scope="scope">
              <div>
                <span
                  class="aLink"
                  @click="
                    $fun.routerToPage(
                      '/deliveryDetails?type=purchase&order_sn=' +
                        scope.row.porder_sn,
                      true
                    )
                  "
                >
                  {{ scope.row.porder_sn }}
                </span>
              </div>
            </template>
          </el-table-column>
          <!-- 出货时间 -->
          <el-table-column
            property="date"
            :label="$fanyi('发送时间')"
            width="120px"
          >
            <template slot-scope="scope">
              <div>
                {{ (scope.row.porder_time_node || {}).datetime_delivery }}
              </div>
            </template>
          </el-table-column>
          <!-- 签收时间 -->
          <el-table-column
            property="date"
            :label="$fanyi('接待时间')"
            width="120px"
          >
            <template slot-scope="scope">
              <div>
                {{ (scope.row.porder_time_node || {}).datetime_client_receive }}
              </div>
            </template>
          </el-table-column>
          <!-- 体积 -->
          <el-table-column
            property="volume"
            :label="$fanyi(`${$fanyi('体积')} (m³)`)"
          ></el-table-column>
          <!-- 重量 -->
          <el-table-column
            property="weight"
            :label="$fanyi(`${$fanyi('重量')} (KG)`)"
          ></el-table-column>
          <!-- 状态 -->
          <el-table-column property="statusNameEu" :label="$fanyi('使用状态')">
          </el-table-column>
          <!-- 操作 -->
          <el-table-column property="date" :label="$fanyi('行动')">
            <template slot-scope="scope">
              <div>
                <div
                  class="yellowFontBtn"
                  @click="
                    $fun.routerToPage(
                      '/deliveryDetails?type=purchase&order_sn=' +
                        scope.row.porder_sn,
                      true
                    )
                  "
                  v-if="['等待支付'].includes(scope.row.statusName)"
                >
                  {{ $fanyi("确认付款") }}
                </div>
                <div
                  class="blueFontBtn"
                  @click="qianShou(scope.row.porder_sn)"
                  v-if="['西班牙派送配送单'].includes(scope.row.statusName)"
                >
                  {{ $fanyi("确定签收") }}
                </div>
              </div>
            </template></el-table-column
          >
        </el-table>
      </div>
      <div class="allpag">
        <a
          href="javascript:;"
          @click="
            form.page = 1;
            getData();
          "
          >{{ $fanyi("回到首页") }}</a
        >
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="form.page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from "../../../../components/public/Dialog.vue";
import { daysDistance, getCurrentDay } from "../../../../utlis/date";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      getCurrentDay,
      daysDistance,
      form: {
        status: "全部",
        page: 1,
        pageSize: 10,
        searchText: "",
      },
      value1: null,
      logisticsLoading: false,
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("确定"), this.$fanyi("取消")],
      },
      tableData: [],
      total: 0,
      oKey: 1,
      ordersSumList: [],
    };
  },
  computed: {},

  created() {
    if (this.$route.query.status) {
      // this.form.status = this.$route.query.status;
      switch (this.$route.query.status) {
        case "orderForDelivery":
          this.form.status = "待发货配送单";
          break;
        case "deliveryInTransit":
          this.form.status = "运输中配送单";
          break;
        case "deliveryFormToRAKUMARTTransportCentre":
          this.form.status = "到达RAKUMART运输中心配送单";
          break;
        case "spanishDeliveryOrder":
          this.form.status = "西班牙派送配送单";
          break;
        case "internationalLogistics":
          this.form.status = "已签收配送单";
          break;
        default:
          this.form.status = this.$route.query.status;
          break;
      }
    }
    if (this.$route.query.type) {
      this.form.status = this.$fun.fromCode(this.$route.query.type);
    }
    this.$forceUpdate();
    this.getData();
    this.getTitleNumber();
  },
  methods: {
    // 获取物流跟踪信息
    getlogisticsInformation(tableDataItem) {
      this.logisticsLoading = true;
      tableDataItem.logisticsData = {};
      this.$api
        .getPorderExpressTrace({ express_no: tableDataItem.express_no })
        .then((res) => {
          ////console.log('事件名',res)
          this.logisticsLoading = false;
          if (res.code != 0 || res.data == [] || res.data == null) return;
          //接下来的操作
          tableDataItem.logisticsData = res.data;
          this.$forceUpdate();
        });
    },
    // 获取种类数量数据
    getTitleNumber() {
      this.$api.logisticsListDataNumV2().then((res) => {
        if (res.code != 0) return false;
        if (res.data.length == 0) return false;
        this.ordersSumList = res.data;
      });
    },
    // 获取数据
    getData() {
      this.$api.logisticsListV2(this.form).then((res) => {
        if (res.code != 0) return false;

        this.tableData = res.data.data;
        this.dataProcessing(this.tableData);
        this.total = res.data.total;
        this.oKey++;
      });
    },
    //派送中配送单签收
    qianShou(sn) {
      this.$refs.dialog.open(
        this.$fanyi("是否签收选中的商品？"),
        () => {
          this.$api
            .internationalLogisticsClientReceive({ porder_sn: sn })
            .then((res) => {
              //console.log("internationalLogisticsClientReceive", res);
              if (res.code != 0) return;
              this.$message.success(this.$fanyi("签收成功"));
              this.getData();
              this.getTitleNumber();
            });
        },
        () => {
          this.$message(this.$fanyi("已取消签收"));
        }
      );
    },
    // 数据处理
    dataProcessing(data) {},
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.form.pageSize = 1;
      this.getData(this.form);
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData(this.form);
    },
    setFromDate() {
      if (this.value1) {
        this.form.startTime = this.value1[0];
        this.form.endTime = this.value1[1];
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
      }
    },
    getStatisticsData(val) {
      return (
        this.$fun.findValue(this.ordersSumList, val, {
          keyName: "status",
          valueName: "num",
        }) || 0
      );
    },

    changestatus(in_status) {
      this.tableData = [];
      this.form.status = in_status;
      this.form.page = 1;
      this.getData(this.form);
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/css/mixin.scss";
.logisticsInformationBox {
  padding: 12px 8px 5px;
  max-height: 500px;
  overflow: auto;
  display: flex;
  min-height: 50px;
  position: relative;
  &::-webkit-scrollbar {
    width: 2px;
  }

  .rightConBox {
    position: relative;
    z-index: 1;
    height: 100%;
    .leftBorderBox {
      width: 1px;
      height: 100%;
      background-image: url("../../../../assets/icon/wuliuLine.png");
      position: absolute;
      left: 16px;
    }
    .logoptBOx {
      display: flex;
      position: relative;
      z-index: 1;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
        background-color: white;
      }
      .actLogistics {
        width: 32px;
        flex: 0 0 32px;
        height: 32px;
        background-color: #2c4bdf;
        box-shadow: 0px 6px 12px 0px rgba(44, 75, 223, 0.2);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 9px;
        img {
          width: 19px;
          height: 17px;
        }
      }
      .normalIcon {
        background: #ffffff;
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
        img {
          width: 15px;
          height: 10px;
        }
      }
      .logisticsFontBox {
        .logisticsTitle {
          font-size: 14px;
          margin-bottom: 5px;
          line-height: 20px;
        }
        .logisticsCon {
          display: flex;
          align-items: center;
          .wuliuDateIcon {
            margin-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            top: -0.7px;
            height: 16px;
            img {
              width: 12px;
              height: 12px;
              flex: 0 0 12px;
            }
          }
          .wuliuDateFont {
            font-size: 12px;
            height: 16px;
            color: #999999;
          }
        }
      }
    }
  }
}
.logisticsnoDataBox {
  width: 100%;
  font-size: 13px;
  color: #c0c4cc;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#userOrder {
  @import "../../../../css/mixin";
  width: 100%;

  button {
    cursor: pointer;
  }

  > nav {
    display: flex;
    background-color: #e8e8e8;

    > span {
      @extend .dip;
      flex-direction: column;
      height: 64px;
      padding: 13px;
      background: #e8e8e8;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #000000;
      line-height: 16px;
      cursor: pointer;
      position: relative;
      flex: 1;

      &::after {
        content: " ";
        width: 1px;
        height: 36px;
        background: #cccccc;
        position: absolute;
        right: 0;
      }

      &.active {
        background-color: #ffffff;
        border-bottom: none;
      }
      &.active::after {
        background: #ffffff;
      }
      &:last-child::after {
        background-color: transparent;
      }
      .label {
        line-height: 18px;
        white-space: nowrap;
        display: block;
      }
      span.wrapTitle {
        white-space: normal;
        line-height: 1.1;
      }

      .numberCount {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #1e2997;
      }
    }
    > span.allStatusSpan {
      flex: 0 0 60px;
    }
    .yellowIconBox {
      flex: 0 0 40px;
      .yellowIcon {
        @extend .dip;
        width: 24px;
        height: 24px;
        background: #fbbb21;
        border-radius: 50%;
        color: #fff;
      }
    }
  }

  > div {
    // min-height: 720px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-top: none;
    padding: 20px 20px 37px 20px;
    .searchBox {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      gap: 12px;
      .myInput {
        height: 40px;

        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;

        > i {
          width: 20px;
          height: 24px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }

        > input {
          border: none;
          border-radius: 4px;
          border: 1px solid #c0c4cc;
          width: 100%;
          height: 100%;
          font-size: 14px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;

          &:focus {
            border-color: #1e2997;
          }
        }
      }

      /deep/.el-date-editor {
        height: 40px;
        margin-right: 20px;
        border-radius: 4px;

        .el-range-input {
          font-size: 14px;
        }

        .el-range-separator {
          width: 50px;
        }
      }
    }

    .dataBody {
      margin-bottom: 40px;

      /deep/thead {
        .el-table__cell {
          width: 50px;
          height: 48px;
          background: #f0f0f0;
          padding: 5px 0;
          .cell {
            font-weight: 400;
            word-break: keep-all;
            font-size: 12px;
            line-height: 1.3;
            padding: 0 5px;
          }
        }
      }

      /deep/tbody {
        .el-table__cell {
          padding: 10px 0;
          height: 60px;
          .cell {
            @extend .dip;
            min-height: 36px;
            font-weight: 400;
            word-break: keep-all;
            font-size: 12px;
            line-height: 1.5;
            color: #000000;
          }
        }
      }

      .timeBox {
        word-break: keep-all;
      }

      .exportBox {
        img {
          width: 20px;
          cursor: pointer;
        }
      }
    }

    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0;

      > a {
        display: inline-block;
        border: 1px solid #dcdfe6;
        height: 28px;
        background-color: white;
        text-align: center;
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 2px;
      }

      /* 分页被选中的页码样式 */
      /deep/.el-pager li {
        margin: 0 3px;
        border-radius: 4px;
        box-shadow: 0px 0px 6px 0px rgba(61, 61, 61, 0.1);

        &:hover {
          color: $homePageSubjectColor;
        }

        &.active {
          background-color: $homePageSubjectColor;
          color: white;
        }
      }
    }
  }
}

.yellowFontBtn {
  width: 92px;
  height: 29px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #f0a52c;
}
.blueFontBtn {
  @extend .yellowFontBtn;
  color: #1e2997;
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;
  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}

.searchBtn {
  width: 90px;
  height: 40px;
  background: $homePageSubjectColor;
  border-radius: 4px;

  color: #ffffff;
  font-size: 16px;
}

.searchBtn.peyBtn {
  width: 100px !important;
}
.fontBlueBtn_h {
  padding: 0 20px;
  font-size: 16px;
  font-weight: 400 !important;
  cursor: pointer;
}

.aLink {
  color: #1e2997;
  text-decoration: none;
}
</style>
